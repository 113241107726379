<template>
  <div :id="uid" class="w-100 my-date-pick-main" :class="{'form-inline': isInline, 'mb-3': hasBottomMargin}">
    <label
        class="multi-select-label"
        v-if="title"
        :for="name"
        style="width: auto !important;"
        :class="labelClasses"
    >{{ title }}<span class="text-danger" v-if="required" >*</span>

      <info-tooltip
          :helper-text="helperText"
          v-if="helperText"
      ></info-tooltip>
    </label>
    <div :class="formGroupClasses"
    >
      <date-pick
          v-model="modelProxy"
          :format="format"
          :inputAttributes="{
            class: 'my-date-pick form-control flex-grow-1 ' + inputClasses
          + (isValid == null ? '' : isValid ? ' is-valid' : ' is-invalid') + (size.toString() === 'sm' ? ' form-control-sm' :
            size.toString() === 'lg' ? ' form-control-lg' : '') + (modelProxy !== null ? ' is-valid': ''),
            disabled: disabled,
            style: {maxWidth: inputMaxWidth + ' !important', width: inputWidth + ' !important'},
            readlonly: true,
          }"
          :weekdays="weekdays"
          :months="months"
          :disabled="disabled"
      ></date-pick>
      <span class="form-text text-muted" v-if="error || hint"
            :class="{'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': isInline}">{{ hint }}</span>
      <p class="text-danger pt-1 px-3 block" :class="{'offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12': isInline}"
         v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'bootstrap-datepicker';

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import {MOMENT_DATE_FORMAT_FOR_KT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import $ from 'jquery';

export default {
  name: "DatePickerInput",
  props: {
    name: {},
    model: {},
    title: {},
    error: {},
    hint: {},
    placeholder: {},
    required: {
      default: true
    },
    includeLabel: {
      default: true,
    },
    isInline: {
      required: false,
      default: true,
    },
    disabled: {
      default: false,
      required: false,
    },
    isValid: {
      required: false,
      default: null,
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    inputMaxWidth: {
      required: false,
      default: '150px'
    },
    inputWidth: {
      required: false,
      default: 'w-auto',
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
    formGroupClass: {
      required: false,
      default: null,
    },
    labelClass: {
      required: false,
      default: null,
    },
    inputClasses: {
      required: false,
      default: null,
    },
    hasBottomMargin: {
      required: false,
      default: true,
    }
  },
  components: {
    DatePick,
    InfoTooltip
  },
  data() {
    return {
      uid: 'rdm_uid',
      format: MOMENT_SYSTEM_DATE_FORMAT,
      weekdays: [this.$t("dateTime.mon"), this.$t("dateTime.tue"), this.$t("dateTime.wed"), this.$t("dateTime.thu"), this.$t("dateTime.fri"), this.$t("dateTime.sat"), this.$t("dateTime.sun")],
      months: [this.$t("dateTime.january"), this.$t("dateTime.february"), this.$t("dateTime.march"), this.$t("dateTime.april"), this.$t("dateTime.may"), this.$t("dateTime.june"), this.$t("dateTime.july"), this.$t("dateTime.august"), this.$t("dateTime.september"), this.$t("dateTime.october"), this.$t("dateTime.november"), this.$t("dateTime.december")],
    }
  },
  computed: {
    labelClasses() {
      let result = {
        'col-form-label-sm': this.size.toString() === 'sm',
        'col-form-label-lg': this.size.toString() === 'lg',
        'd-inline-block': this.isInline,
        'd-inline': !this.isInline,
      };
      if (Object(this.labelClass) === this.labelClass) {
        result = {...result, ...this.labelClass};
      } else if (Array.isArray(this.labelClass)) {
        this.labelClass.forEach(value => {
          result[value] = true
        })
      } else if (this.labelClass !== null) {
        result[this.labelClass] = true;
      }
      return result;
    },
    formGroupClasses() {
      let result = {
        'mx-3': this.isInline,
        'd-inline-block': this.isInline,
        'd-inline': !this.isInline,
      };

      if (Object(this.formGroupClass) === this.formGroupClass) {
        result = {...result, ...this.formGroupClass};
      } else if (Array.isArray(this.formGroupClass)) {
        this.formGroupClass.forEach(value => {
          result[value] = true
        })
      } else if (this.formGroupClass !== null) {
        result[this.formGroupClass] = true;
      }
      return result;
    },
    modelProxy: {
      set(value) {
        this.$emit('update:model', moment(value, MOMENT_SYSTEM_DATE_FORMAT))
      },
      get() {
        if (this.model == null) {
          return moment().format(MOMENT_DATE_FORMAT_FOR_KT);
        }
        return moment(this.model).format(MOMENT_DATE_FORMAT_FOR_KT);
      },
    }
  },
  mounted() {
    this.uid = this.randomStr(5, false);
    if(this.inputClasses != null) {
      $(`#${this.uid} .vdpComponent input`).toggleClass(this.inputClasses);
    }
  }
}
</script>
<style>

.vdpClearInput {
  display: none !important;;
}
.vdpClearInput:before {
  content: none !important;
}
.vdpComponent{
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.vdpComponent{
  padding-top: 0 !important;
}
.vdpHeadCell {
  padding: .3em .4em 1.8em !important;
}
</style>
